import React from 'react';
import styled from 'styled-components';
import {navigate} from 'gatsby';
import moment from 'moment';
import {Table, Button, message, Input, Space, Modal} from 'antd';
import {EyeOutlined, SearchOutlined} from '@ant-design/icons';
import queryString from 'query-string';
import config from '../../../data.json';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import {
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_CATEGORY,
  LOGISTICE_TYPE_DISPLAY,
  PRODUCT_TYPE,
} from '../../domain/Constants';
import ModalContent from '../../components/ModalContent';
import CalendarForm from './CalendarForm';
import StockPreviewModalContent from './StockPreviewModalContent';
import * as Validate from '../../domain/Validate';
import FileUploadPanelBtn from '../../components/FileUploadPanelBtn';
import {generateDates} from '../../utils/date';
import * as LogisticUtil from '../../domain/Logistic';
import {isValidProductSpec} from '../../domain/FormatValidator';
import DashboardProductCatFileUpdateButton from '../../components/DashboardProductCatFileUpdateButton';
import {useOutlet} from 'reconnect.js';
import ImagePreviewer from '../../components/ImagePreivewer';

const PATH = URL_PATH.dashboardProducts;
const PAGE_SIZE = 10;
const SEARCH_FIELDS = ['name', 'category'];
const uiSchema = {
  id: {
    'ui:readonly': true,
  },
  description: {
    'ui:widget': 'textarea',
  },
  enterprise_product_min_buy: {
    'ui:help': '若無最小購買數限制，請填 0',
  },
  enterprise_product_max_buy: {
    'ui:help': '若無最大購買數限制，請填 99999',
  },
  spec: {
    items: {
      prepare_day: {
        'ui:help':
          '已建立日曆後若要修改，既存日曆需刪除再重新設定，否則電商端顯示會錯誤',
      },
      box_spec: {
        'ui:options': {
          orderable: false,
          removable: false,
        },
      },
    },
  },
  image: {
    'ui:widget': (props) => <ImagePreviewer {...props} />,
  },
  images: {
    items: {
      url: {
        'ui:widget': (props) => <ImagePreviewer {...props} />,
      },
    },
  },
};

const basedSpecProp = {
  type: 'array',
  title: '規格',
  minItems: 1,
  items: {
    type: 'object',
    required: ['item_id', 'name', 'price', 'prepare_day'],
    properties: {
      item_id: {
        type: 'string',
        title: '商品編號',
      },
      name: {
        type: 'string',
        title: '規格名稱',
      },
      price: {
        type: 'number',
        title: '價格',
      },
      prepare_day: {
        type: 'number',
        title: '備貨時間（天）',
        default: 4,
      },
      box_spec: {
        type: 'array',
        title: '材積規格(小/中/大)',
        minItems: 3,
        maxItems: 3,
        items: [
          {
            type: 'number',
            title: '小盒至多可放數量',
            default: 1,
            minimum: 0,
          },
          {
            type: 'number',
            title: '中盒至多可放數量',
            default: 1,
            minimum: 0,
          },
          {
            type: 'number',
            title: '大盒至多可放數量',
            default: 1,
            minimum: 1,
          },
        ],
      },
    },
  },
};

function ProductsPage(props) {
  const [records, setRecords] = React.useState([]);
  const [additionalProducts, setAdditionalProducts] = React.useState([]);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [tableStateSnapshot, setTableStateSnapshot] = React.useState({
    pagination: {
      current: 1,
      total: 0,
      pageSize: PAGE_SIZE,
    },
  });
  const [fetchConfigSnapshot, setFetchConfigSnapshot] = React.useState({
    paging: {
      limit: PAGE_SIZE,
      offset: 0,
    },
    sorting: ['-created'],
  });
  const app = React.useContext(AppContext.Context);
  const params = queryString.parse(props.location.search);
  const selectedId = params.id;
  const [categories, setCategories] = React.useState(null);
  const categoryList = React.useMemo(() => {
    let _result = [];
    if (categories && Array.isArray(categories.children)) {
      // flatten children, we know we have only two layer
      for (let c of categories.children) {
        _result.push(c);
        for (let child of c.children || []) {
          _result.push({
            ...child,
            name: `${c.name}__${child.name}`,
          });
        }
      }

      return _result.filter((c) => c.published);
    }
    return [];
  }, [categories]);

  const schema = {
    title: '編輯商品',
    type: 'object',
    required: ['name', 'description', 'allow_logistic_type'],
    properties: {
      published: {
        title: '已上架',
        type: 'boolean',
        default: false,
      },
      name: {
        type: 'string',
        title: '品名',
      },
      description: {type: 'string', title: '描述'},
      hint: {type: 'string', title: '補充說明（顯示於品名下方）'},
      type: {
        title: '商品型別',
        type: 'string',
        anyOf: [
          {
            type: 'string',
            enum: [PRODUCT_TYPE.default],
            title: '預設',
          },
          {
            type: 'string',
            enum: [PRODUCT_TYPE.additional],
            title: '加購',
          },
          {
            type: 'string',
            enum: [PRODUCT_TYPE.enterprise],
            title: '限量',
          },
        ],
        default: PRODUCT_TYPE.default,
      },
      allow_logistic_type: {
        title: '物流方式',
        type: 'array',
        uniqueItems: true,
        default: [LOGISTICE_TYPE.selfPick],
        items: {
          type: 'string',
          anyOf: [
            LOGISTICE_TYPE.selfPick,
            LOGISTICE_TYPE.icatFreeze,
            LOGISTICE_TYPE.icatFridge,
            LOGISTICE_TYPE.icatNormal,
          ].map((type) => ({
            type: 'string',
            enum: [type],
            title: LOGISTICE_TYPE_DISPLAY[type],
          })),
        },
      },
      self_pick_location: {
        type: 'string',
        title: '快閃店',
      },
      available_times_self_pick: {
        type: 'array',
        title: '自取可取貨時間',
        minItems: 1,
        default: [
          '11:30',
          '12:00',
          '12:30',
          '13:00',
          '13:30',
          '14:00',
          '14:30',
          '15:00',
          '15:30',
          '16:00',
          '16:30',
          '17:00',
          '17:30',
          '18:00',
          '18:30',
          '19:00',
        ],
        items: {
          type: 'string',
          default: '',
        },
      },
      available_times_home: {
        type: 'array',
        title: '宅配可到貨時間',
        minItems: 1,
        default: ['8:00-13:00', '13:00-18:00'],
        items: {
          type: 'string',
          default: '',
        },
      },
      spec: {
        ...basedSpecProp,
      },
      image: {
        type: 'string',
        title: '商品主圖',
      },
      images: {
        type: 'array',
        title: '商品副圖',
        items: {
          type: 'object',
          properties: {
            url: {
              type: 'string',
            },
          },
        },
      },
      category: {
        title: '商品類別',
        type: 'array',
        uniqueItems: true,
        items: {
          default: '',
          type: 'string',
          title: '類別名稱',
          anyOf: categoryList.map((c) => ({
            type: 'string',
            enum: [c.key],
            title: c.name,
          })),
        },
        default: [],
      },
    },
    dependencies: {
      type: {
        oneOf: [
          {
            properties: {
              type: {
                enum: [PRODUCT_TYPE.additional],
              },
              additional_product_stock: {
                title: '加購商品庫存',
                type: 'number',
                default: 0,
              },
            },
            required: ['additional_product_stock'],
          },
          {
            properties: {
              type: {
                enum: [PRODUCT_TYPE.default],
              },
              allow_additional_custom_card: {
                type: 'boolean',
                title: '可客製化插牌',
              },
              // allow_additional_tablewares: {
              //   type: 'boolean',
              //   title: '可加購盤叉',
              // },
              // has_free_candle: {
              //   type: 'boolean',
              //   title: '附贈蠟燭',
              //   default: false,
              // },
              recommend_additional_products: {
                type: 'array',
                title: '推薦加購商品',
                default: [],
                uniqueItems: true,
                items: {
                  default: '',
                  type: 'string',
                  title: '商品',
                  anyOf: additionalProducts.map((c) => ({
                    type: 'string',
                    enum: [c.id],
                    title: c.name,
                  })),
                },
              },
            },
            required: [],
          },
          {
            properties: {
              type: {
                enum: [PRODUCT_TYPE.enterprise],
              },
              images: {
                type: 'array',
                title: '商品副圖',
                items: {
                  type: 'object',
                  properties: {
                    url: {
                      type: 'string',
                    },
                  },
                },
              },
              spec: {
                ...basedSpecProp,
                maxItems: 1,
              },
              enterprise_product_min_buy: {
                title: '限量商品單筆最低購買數',
                type: 'number',
                default: 1,
              },
              enterprise_product_max_buy: {
                title: '限量商品單筆最大購買數',
                type: 'number',
                default: 0,
              },
              enterprise_published_time_start: {
                title: '限量商品開放購買時間',
                type: 'string',
                format: 'date-time',
              },
              enterprise_published_time_end: {
                title: '限量商品結束購買時間',
                type: 'string',
                format: 'date-time',
              },
              recommend_additional_products: {
                type: 'array',
                title: '推薦加購商品',
                default: [],
                uniqueItems: true,
                items: {
                  default: '',
                  type: 'string',
                  title: '商品',
                  anyOf: additionalProducts.map((c) => ({
                    type: 'string',
                    enum: [c.id],
                    title: c.name,
                  })),
                },
              },
            },
            required: [
              'enterprise_product_min_buy',
              'enterprise_product_max_buy',
              'enterprise_published_time_start',
              'enterprise_published_time_end',
            ],
          },
        ],
      },
    },
  };

  const _updateProductList = React.useCallback(
    async (configs = null) => {
      let _configs = configs ||
        fetchConfigSnapshot || {
          paging: {
            offset: 0,
            limit: PAGE_SIZE,
          },
          sorting: ['-created'],
        };
      try {
        let _resp = await app.actions.getProducts(_configs);
        setTableStateSnapshot((_tableStateSnapshot) => ({
          ..._tableStateSnapshot,
          pagination: {
            ..._tableStateSnapshot.pagination,
            total: _resp.total,
          },
        }));
        setRecords(_resp.results);

        let _additRespResults = await app.actions.getProducts({
          query: {
            type: PRODUCT_TYPE.additional,
          },
          projection: {
            name: 1,
          },
        });
        setAdditionalProducts(_additRespResults);
      } catch (ex) {
        console.warn(ex);
      }
    },
    [app.actions, fetchConfigSnapshot],
  );

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      if (!categories) {
        await _updateCategories();
      }
      if (selectedId) {
        try {
          setSelectedRecord(await app.actions.getProductById(selectedId));
        } catch (ex) {
          console.warn(ex);
        }
      } else {
        setSelectedRecord(null);
        await _updateProductList();
      }

      AppActions.setLoading(false);
    }

    fetchData();
  }, [app.actions, selectedId, categories, _updateProductList]);

  const _updateCategories = React.useCallback(async () => {
    try {
      setCategories(await app.actions.getCategories());
    } catch (ex) {
      console.warn(ex);
      setCategories({children: []});
      message.warn('無法取得商品類別資料');
    }
  }, [app.actions]);

  const _onDeleteProduct = React.useCallback(
    async (record) => {
      const _confirmDelete = async () => {
        const hide = message.loading('刪除商品中...', 0);
        try {
          await app.actions.staffDeleteProduct(record.id);
          hide();
          message.success('商品已刪除');
        } catch (err) {
          //
          hide();
          message.error('刪除商品失敗');
        }
        AppActions.setLoading(true);
        await _updateProductList(fetchConfigSnapshot);
        AppActions.setLoading(false);
      };

      Modal.confirm({
        title: `是否確定刪除商品：${record.name}？`,
        content: '',
        cancelText: '取消',
        okText: '刪除',
        onOk: _confirmDelete,
      });
    },
    [app.actions, fetchConfigSnapshot],
  );

  const tableColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '品名',
      dataIndex: 'name',
      key: 'name',
      filteredValue: tableStateSnapshot.filters?.name || null,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={`商品名稱`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button onClick={clearFilters} size="small" style={{width: 90}}>
              重置
            </Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                console.log(selectedKeys, confirm, 'id');
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{width: 90}}>
              搜尋
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '類別',
      dataIndex: 'category',
      key: 'category',
      render: (_, record, idx) => {
        return categoryList
          .filter((cat) => _.indexOf(cat.key) !== -1)
          .map((c) => c.name)
          .join(', ');
      },
      filteredValue: tableStateSnapshot.filters?.category || null,
      filters: categoryList.map((cat) => ({
        text: cat.name,
        value: cat.key,
      })),
    },
    {
      title: '狀態',
      dataIndex: 'published',
      key: 'published',
      render: (_, record, idx) => {
        return record.published ? '上架' : '-';
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return (
          <Button
            onClick={() => {
              navigate(`${PATH}?id=${record.id}`);
            }}>
            編輯
          </Button>
        );
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 100,
      render: (_, record, idx) => {
        return <Button onClick={() => _onDeleteProduct(record)}>刪除</Button>;
      },
    },
  ];

  const _onGoCreate = React.useCallback(() => {
    setSelectedRecord({
      id: null,
    });
  }, []);

  const _onCreateStockCalendar = React.useCallback(
    async (data) => {
      if (
        !Validate.validDateString(data.production_date_from) ||
        !Validate.validDateString(data.production_date_to)
      ) {
        message.error('輸入的日期格式不符喔！格式須為 YYYY-MM-DD');
        return;
      }

      if (
        selectedRecord.type === PRODUCT_TYPE.enterprise &&
        (!Validate.validDateString(data.entp_valid_from) ||
          !Validate.validDateString(data.entp_valid_to))
      ) {
        message.error('輸入的日期格式不符喔！格式須為 YYYY-MM-DD');
        return;
      }

      const hide = message.loading('創建日曆及庫存中...', 0);

      try {
        let _productionDays = generateDates(
          data.production_date_from,
          data.production_date_to,
          true,
          data.production_week_days,
        );

        let _homeCalendarDataList = _productionDays
          .map((pd) => ({
            item_id: data.item_id,
            logistic_type: LOGISTICE_TYPE_CATEGORY.home,
            production_date: pd,
            delivery_date: LogisticUtil.getHomeDeliveryDate(pd),
          }))
          .filter(LogisticUtil.isHomeProductionDateValid);

        let _selfPickCalendarDataList = _productionDays.map((pd) => ({
          item_id: data.item_id,
          logistic_type: LOGISTICE_TYPE_CATEGORY.selfPick,
          production_date: pd,
          delivery_date: pd,
        }));

        let _calendarDataList = _homeCalendarDataList.concat(
          _selfPickCalendarDataList,
        );

        let _stockData = {
          item_id: data.item_id,
          dates_to_create: _productionDays,
          quantity: data.stock,
        };

        if (selectedRecord.type === PRODUCT_TYPE.enterprise) {
          let entpCalendarResp = await app.actions.staffMultiCreateEntpCalendar(
            {
              cal_data: _calendarDataList,
              total_stock: data.entp_stock,
              valid_from: data.entp_valid_from,
              valid_to: data.entp_valid_to,
            },
          );
        } else {
          await app.actions.staffMultiCreateCalendar(_calendarDataList);
        }
        await app.actions.staffCreateStock(_stockData);

        hide();
        message.success('創建成功');
      } catch (err) {
        //
        hide();
        message.error('創建失敗');
      }
    },
    [app.actions, selectedRecord],
  );

  const _openEditCalendarModal = React.useCallback(() => {
    if (selectedRecord.id === null) {
      message.warn('請先完成商品創建，再新增日曆！');
      return;
    }
    AppActions.setModal(
      <ModalContent title={'新增日曆'}>
        <CalendarForm
          product={selectedRecord}
          calendar={null}
          onSubmit={_onCreateStockCalendar}
        />
      </ModalContent>,
    );
  }, [selectedRecord]);

  const _openStockPreviewModal = React.useCallback(() => {
    AppActions.setModal(
      <ModalContent title={'日曆預覽'}>
        <StockPreviewModalContent product={selectedRecord} />
      </ModalContent>,
    );
  }, [selectedRecord]);

  const _onDataUpdate = React.useCallback(
    async (formData) => {
      // validate box_spec config
      if (!isValidProductSpec(formData.spec)) {
        message.error('請檢查商品規格：材積規格(小/中/大)必須為小至大！');
        return;
      }

      AppActions.setLoading(true);
      let _result = false;
      try {
        if (!formData.id) {
          let _article = await app.actions.staffCreateArticle();
          let _updatedProduct = await app.actions.staffCreateProduct({
            ...formData,
            intro_article_id: _article.id,
          });

          setSelectedRecord(_updatedProduct);
          await _updateProductList(fetchConfigSnapshot);
        } else {
          let _updatedProduct = await app.actions.staffUpdateProduct(formData);
          setSelectedRecord(_updatedProduct);
        }
        _result = true;
      } catch (err) {
        //
        _result = false;
      }
      AppActions.setLoading(false);

      _result ? message.success('資料已更新') : message.error('資料更新失敗');
    },
    [fetchConfigSnapshot],
  );

  const _openPrdocutPreview = React.useCallback(() => {
    window.open(`${URL_PATH.productPreview}?id=${selectedRecord.id}`, '_blank');
  }, [selectedRecord]);

  const _onEditArticle = React.useCallback(async () => {
    let _editorUrl = await app.actions.staffGetArticleEditorUrl(
      selectedRecord.intro_article_id,
    );
    window.open(_editorUrl, '_blank');
  }, [selectedRecord]);

  const _onPreviewArticle = React.useCallback(async () => {
    let _editorUrl = await app.actions.staffGetPreviewArticleUrl(
      selectedRecord.intro_article_id,
    );
    window.open(_editorUrl, '_blank');
  }, [selectedRecord]);

  const _onTableChange = React.useCallback(
    async (
      pagination, // {current: 1, pageSize: 3, total: 100}
      filters,
      sorter,
      extra,
    ) => {
      let _configs = {
        paging: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        query: {},
        sorting: ['-created'],
      };

      let _queries = [];
      for (let key of Object.keys(filters || {})) {
        if (filters[key] && filters[key].length > 0) {
          if (SEARCH_FIELDS.indexOf(key) !== -1) {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: {$regex: `.*${v}.*`}})),
            };
            _queries.push(_resultTypeQuery);
          } else {
            let _resultTypeQuery = {
              $or: filters[key].map((v) => ({[key]: v})),
            };
            _queries.push(_resultTypeQuery);
          }
        }
      }
      if (_queries.length > 0) {
        if (_configs.query.$and) {
          _configs.query.$and.push(..._queries);
        } else {
          _configs.query.$and = _queries;
        }
      }

      if (extra.action === 'paginate') {
        _configs.paging.offset = (pagination.current - 1) * pagination.pageSize;
      }

      if (sorter && sorter.field && sorter.order) {
        _configs.sorting = [
          `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`,
        ];
      }

      AppActions.setLoading(true);
      setTableStateSnapshot({
        pagination,
        filters,
        sorter,
      });
      setFetchConfigSnapshot(_configs);
      AppActions.setLoading(false);
    },
    [_updateProductList],
  );

  const _copyProductShareLink = React.useCallback(async () => {
    let text = app.actions.getProductShareLink(selectedRecord);
    try {
      await navigator.clipboard.writeText(text);
      message.success(`已複製連結`);
    } catch (err) {
      message.warn(`無法複製連結，建議更新瀏覽器或使用 Chrome`);
    }
  }, [selectedRecord, app.actions]);

  const uiSchemaPropsBasedOnValue = React.useMemo(() => {
    let result = {};
    if (selectedRecord?.id) {
      // dis-allow editing when instance has been created
      result['type'] = {
        'ui:readonly': true,
      };
    }

    return result;
  }, [selectedRecord]);

  if (!selectedRecord) {
    return (
      <Wrapper>
        <h2>商品列表</h2>
        <Button style={{marginBottom: 10}} onClick={_onGoCreate}>
          新增商品
        </Button>
        <DashboardProductCatFileUpdateButton
          style={{marginBottom: 10, marginLeft: 10}}
        />
        <Table
          dataSource={records}
          columns={tableColumns}
          rowKey={(record) => record.id}
          onChange={_onTableChange}
          pagination={{
            ...tableStateSnapshot.pagination,
            showSizeChanger: false,
          }}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <div>
          <Button
            onClick={() => {
              setSelectedRecord(null);
              navigate(PATH);
            }}>
            返回
          </Button>

          <Button
            icon={<EyeOutlined />}
            onClick={_openPrdocutPreview}
            style={{marginLeft: 10}}>
            預覽商品
          </Button>
        </div>

        <JStorageForm
          schema={schema}
          uiSchema={{
            ...uiSchema,
            ...uiSchemaPropsBasedOnValue,
          }}
          instance={selectedRecord}
          onSubmit={_onDataUpdate}
        />
        <div className="article">
          {selectedRecord.intro_article_id ? (
            <>
              <Input
                disabled={true}
                value={`商品介紹文章 id：${selectedRecord.intro_article_id}`}
              />
              <Button
                onClick={_onEditArticle}
                style={{marginTop: 10, marginRight: 10}}>
                編輯商品介紹
              </Button>
              <Button onClick={_onPreviewArticle} style={{marginTop: 10}}>
                預覽商品介紹
              </Button>
            </>
          ) : (
            <>
              <Input
                disabled={true}
                value={`商品介紹文章 id：新增商品後將自動產生`}
              />
            </>
          )}
        </div>
        <div className="floating-actions">
          <Button onClick={_openStockPreviewModal}>庫存預覽</Button>
          <Button onClick={_openEditCalendarModal} style={{marginTop: 10}}>
            新增日曆
          </Button>
          <Button onClick={_copyProductShareLink} style={{marginTop: 10}}>
            分享連結
          </Button>
          <FileUploadPanelBtn style={{marginTop: 10}} />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 20px;

  & > h2 {
    margin-bottom: 16px;
  }

  & .floating-actions {
    padding: 10px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: #fff;
    z-index: 10;
    box-shadow: 0px 0px 10px #cecece;
    flex-direction: column;
    display: flex;
  }

  & .article {
    & label {
    }
  }
`;

export default ProductsPage;
