import React, {useState} from 'react';
import {Card, Image, Input, Result} from 'antd';
import {Delete} from '@styled-icons/fluentui-system-filled/Delete';

// this Comp is rjsf Custom Widget

const ImagePreviewer = (props) => {
  const {onChange, value} = props;
  const [isError, setIsError] = useState(false);
  return (
    <div>
      {!!value ? (
        <Card
          actions={[
            <div
              onClick={() => {
                onChange('');
                setIsError(false);
              }}
              style={{display: 'flex', justifyContent: 'center'}}>
              <Delete size={20} />
              刪除
            </div>,
          ]}>
          {isError ? (
            <Result
              status="404"
              title="404"
              subTitle="找不到此張圖片，請刪除後重新上傳圖片"
            />
          ) : (
            <Image
              src={value}
              onError={(e) => {
                setIsError(true);
              }}
            />
          )}
        </Card>
      ) : (
        <Input
          value={value}
          onChange={(e) => {
            const {value} = e.target;
            onChange(value);
          }}
        />
      )}
    </div>
  );
};

export default ImagePreviewer;
