import React from 'react';
import {Upload, Button as AntButton, message} from 'antd';
import styled from 'styled-components';
import * as AppContext from '../AppContext';
import {UploadOutlined} from '@ant-design/icons';

function getRandomId() {
  return (
    Math.random().toString(36).substring(2, 16) +
    Math.random().toString(36).substring(2, 16)
  ).toUpperCase();
}

function FileUploadPanelBtn(props) {
  const app = React.useContext(AppContext.Context);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [image, setImage] = React.useState({
    uid: '',
    url: '',
    fields: null,
    origin_filename: '',
    expected: '',
  });
  const textRef = React.useRef(null);

  const _beforeUpload = async (file) => {
    try {
      const token = await app.actions.getUploadToken();
      const resp = await app.actions.storagePresigned({
        file: {
          type: file.type,
          name: getRandomId(),
        },
        token: token.token,
      });

      setImage((prev) => ({...prev, ...resp}));
      return true;
    } catch (e) {
      console.log(e);
    }
  };

  const _copyToClipboard = React.useCallback(
    async (text) => {
      try {
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
        try {
          await navigator.clipboard.writeText(text);
        } catch (err) {
          // support older browser
          textRef && textRef.current.select();
          document.execCommand('copy');
        }
      } catch (err) {
        console.log('>>', err);
      }
      return true;
    },
    [textRef],
  );

  const _onFileChange = React.useCallback(
    async (info) => {
      const {file} = info;
      if (file.status === 'done') {
        setImage({
          ...image,
          uid: file.uid,
          origin_filename: file.name,
        });

        const imageUrl = `${image.expected}`;
        await _copyToClipboard(imageUrl);
        message.success(`圖片已上傳，圖片連結已複製至剪貼簿`);
      } else if (file.status === 'error') {
        message.error(`圖片上傳失敗，請再試一次`);
      }
    },
    [image],
  );

  return (
    <Wrapper style={{...props.style}}>
      <div className={`panel${openMenu ? ' active' : ''}`}>
        <Upload
          maxCount={1}
          onChange={_onFileChange}
          beforeUpload={_beforeUpload}
          defaultFileList={[]}
          accept="image/*"
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: true,
          }}
          action={image.url}
          data={image.fields}>
          <AntButton style={{margin: '10px 0'}} icon={<UploadOutlined />}>
            上傳
          </AntButton>
        </Upload>

        <div className="image-preview">
          <p>{`${image.expected}` || '預覽連結'}</p>
          {/* FIXME: looks like image may not be ready as soon as api sucess, so we cannot get image even when we got url,
         need to manual reload image url for maybe 500ms
        */}
          {/* <img src={`${image.expected}`} /> */}
        </div>

        <textarea
          style={{opacity: 0, position: 'absolute', width: 0, height: 0}}
          ref={(textarea) => {
            textRef.current = textarea;
          }}
          value={`${image.expected}`}
          onChange={() => 0}
        />
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <AntButton
          className="action-btn"
          onClick={() => setOpenMenu(!openMenu)}>
          {openMenu ? '關閉' : '上傳圖片'}
        </AntButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .action-btn {
  }

  & > .panel {
    display: none;
  }

  & > .panel.active {
    display: flex;
  }
  & > .panel {
    flex-direction: column;
    padding: 10px 20px;
    & .image-preview {
      p {
        font-size: 14px;
      }
      img {
        max-width: 100px;
        max-height: 100px;
      }
    }
  }
`;

export default FileUploadPanelBtn;
