import React from 'react';
import styled from 'styled-components';
import ReserveCalendar from '../../components/ReserveCalendar';
import {Button, Input, message, Select, Modal} from 'antd';
import * as AppContext from '../../AppContext';
import * as Validate from '../../domain/Validate';
import {
  LOGISTICE_TYPE_CATEGORY,
  LOGISTICE_TYPE_CATEGORY_DISPLAY,
  PRODUCT_TYPE,
} from '../../domain/Constants';
import {useOutlet} from 'reconnect.js';

function StockPreviewModalContent(props) {
  const {product} = props;
  const [squirrel] = useOutlet('squirrel');
  const app = React.useContext(AppContext.Context);
  const [selectedSpec, setSelectedSpec] = React.useState(
    product.spec?.[0] || null,
  );
  const [selectedLogisticType, setSelectedLogisticType] = React.useState(
    LOGISTICE_TYPE_CATEGORY.selfPick,
  );
  const [values, setValues] = React.useState({
    removeDateFrom: '',
    removeDateTo: '',
  });

  const [isRemovingCalendar, setIsRemovingCalendar] = React.useState(false);

  const _onSpecSelect = React.useCallback(
    (value) => {
      let _foundSpec = product.spec.find((s) => s.item_id === value);
      _foundSpec ? setSelectedSpec(_foundSpec) : setSelectedSpec(null);
    },
    [product],
  );

  const _onDeleteCalendar = React.useCallback(async () => {
    if (
      !Validate.validDateString(values.removeDateFrom) ||
      !Validate.validDateString(values.removeDateTo)
    ) {
      message.error('日期格式不正確，請檢查日期欄位');
      return;
    }

    const _removeCalendar = async () => {
      setIsRemovingCalendar(true);
      try {
        await app.actions.staffMultiDeleteCalendar({
          from_date: values.removeDateFrom,
          to_date: values.removeDateTo,
          logistic_type: selectedLogisticType,
          item_id: selectedSpec.item_id,
        });
        message.success(
          `${LOGISTICE_TYPE_CATEGORY_DISPLAY[selectedLogisticType]} 日曆：${values.removeDateFrom}~${values.removeDateTo}已移除`,
        );
        setValues({
          removeDateFrom: '',
          removeDateTo: '',
        });
      } catch (err) {
        //
        message.error('無法移除日曆！');
      }
      setIsRemovingCalendar(false);
    };

    Modal.confirm({
      title: `是否確定要刪除 ${values.removeDateFrom}~${values.removeDateTo} 區間「${LOGISTICE_TYPE_CATEGORY_DISPLAY[selectedLogisticType]}」商品規格「${selectedSpec.name}」的日曆？`,
      content: '日曆刪除可重新創建新的日曆',
      cancelText: '取消',
      okText: '刪除',
      onOk: _removeCalendar,
    });
  }, [product, values, selectedLogisticType, selectedSpec]);
  // isStaff -> show stock / allow delete delivery date for certain type
  return (
    <Wrapper>
      <div className="configs">
        <div className="item">
          <label>取貨方式</label>
          <Select
            value={selectedLogisticType}
            onChange={(v) => setSelectedLogisticType(v)}>
            <Select.Option value={LOGISTICE_TYPE_CATEGORY.selfPick}>
              {
                LOGISTICE_TYPE_CATEGORY_DISPLAY[
                  LOGISTICE_TYPE_CATEGORY.selfPick
                ]
              }
            </Select.Option>
            <Select.Option value={LOGISTICE_TYPE_CATEGORY.home}>
              {LOGISTICE_TYPE_CATEGORY_DISPLAY[LOGISTICE_TYPE_CATEGORY.home]}
            </Select.Option>
          </Select>
        </div>

        <div className="item">
          <label>規格</label>
          <Select
            value={selectedSpec ? selectedSpec.item_id : null}
            style={{width: 120}}
            onChange={_onSpecSelect}>
            {product.spec
              .slice()
              .sort((a, b) => a.price - b.price)
              .map((s, idx) => (
                <Select.Option key={idx} value={s.item_id}>
                  {s.name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className="item">
          <label>
            刪除特定日期（
            {LOGISTICE_TYPE_CATEGORY_DISPLAY[selectedLogisticType]}）
          </label>
          <Input
            placeholder={'YYYY-MM-DD'}
            onChange={(e) =>
              setValues({...values, removeDateFrom: e.target.value})
            }
            value={values.removeDateFrom}
            style={{width: 200, marginRight: 5}}
          />{' '}
          ~{' '}
          <Input
            placeholder={'YYYY-MM-DD'}
            onChange={(e) =>
              setValues({...values, removeDateTo: e.target.value})
            }
            value={values.removeDateTo}
            style={{width: 200, marginLeft: 5}}
          />
          <Button
            loading={isRemovingCalendar}
            type="danger"
            onClick={_onDeleteCalendar}
            style={{marginLeft: 10}}>
            確認刪除
          </Button>
        </div>
      </div>
      <div className="calendar">
        {!isRemovingCalendar && selectedSpec && selectedLogisticType && (
          <ReserveCalendar
            item={{
              id: selectedSpec.item_id,
              title: selectedSpec.name,
              logisticType: selectedLogisticType,
              quantity: 1,
            }}
            isStaff
            product={product}
          />
        )}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > .configs {
    display: flex;
    flex-direction: column;

    & > .item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      & > label {
        margin-right: 10px;
      }
    }
  }

  & > .calendar {
    min-height: 800px;
  }
`;

export default StockPreviewModalContent;
