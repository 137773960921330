import moment from 'moment';

function getHomeDeliveryDate(productionDate) {
  let _initialDeliveryDate = moment(productionDate, 'YYYY-MM-DD').add(
    1,
    'days',
  );

  let _deliveryDate = _initialDeliveryDate;
  // icat will not delivery on Sunday (0) and Monday (1)
  if (_initialDeliveryDate.day() === 0) {
    _deliveryDate = _initialDeliveryDate.add(2, 'days');
  } else if (_initialDeliveryDate.day() === 1) {
    _deliveryDate = _initialDeliveryDate.add(1, 'days');
  }

  return _deliveryDate.format('YYYY-MM-DD');
}

function isHomeProductionDateValid(homeCalendar) {
  let _productionDate = moment(homeCalendar.production_date, 'YYYY-MM-DD');
  // icat will not delivery on Sunday (0) and Monday (1), so production date for Saturday (6) and Sunday (0) is invalid
  return _productionDate.day() !== 6 && _productionDate.day() !== 0;
}

export {getHomeDeliveryDate, isHomeProductionDateValid};
