import React from 'react';
import * as AppContext from '../AppContext';
import * as AppActions from '../AppActions';
import styled from 'styled-components';
import {Button, message} from 'antd';

function DashboardProductCatFileUpdateButton(props) {
  const {style} = props;
  const app = React.useContext(AppContext.Context);

  const _triggerUpdate = React.useCallback(async () => {
    AppActions.setLoading(true);
    try {
      let resp = await app.actions.staffTriggerProductCatFileUpdate();
      message.success('商品/類別資料已更新！');
    } catch (err) {
      console.log('err', err);
      message.error('無法更新商品/類別資料！請聯絡工程團隊');
    }
    AppActions.setLoading(false);
  });

  return (
    <Button style={style} onClick={_triggerUpdate}>
      更新商品/類別資料
    </Button>
  );
}

export default DashboardProductCatFileUpdateButton;
