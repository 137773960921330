import React from 'react';
import styled from 'styled-components';
import JStorageForm from './JStorageForm';
import URL_PATH from '../../UrlPath';
import {
  LOGISTICE_TYPE,
  LOGISTICE_TYPE_CATEGORY,
  PRODUCT_TYPE,
} from '../../domain/Constants';

const uiSchema = {};

function CalendarForm(props) {
  const {calendar, product, onSubmit} = props;

  const schema = {
    title: '',
    type: 'object',
    required: [
      'item_id',
      'production_date_from',
      'production_date_to',
      'stock',
    ].concat(
      product.type === PRODUCT_TYPE.enterprise
        ? ['entp_valid_from', 'entp_valid_to']
        : [],
    ),
    properties: {
      item_id: {type: 'string', title: '商品編號'},
      production_date_from: {
        type: 'string',
        title: '生產日期起始 含(YYYY-MM-DD)',
      },
      production_date_to: {
        type: 'string',
        title: '生產日期結束 含(YYYY-MM-DD)',
      },
      stock: {
        title: '每日庫存',
        type: 'number',
        default: 0,
      },
      ...(product.type === PRODUCT_TYPE.enterprise
        ? {
            entp_stock: {
              title: '限量商品區間總庫存',
              type: 'number',
              default: 0,
            },
            entp_valid_from: {
              type: 'string',
              title: '限量商品可購買時間起始 含(YYYY-MM-DD)',
            },
            entp_valid_to: {
              type: 'string',
              title: '限量商品可購買時間結束 含(YYYY-MM-DD)',
            },
          }
        : {}),
      production_week_days: {
        title: '庫存生產星期',
        type: 'array',
        minItems: 7,
        maxItems: 7,
        items: [
          {
            type: 'boolean',
            title: '星期日',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期一',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期二',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期三',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期四',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期五',
            default: true,
          },
          {
            type: 'boolean',
            title: '星期六',
            default: true,
          },
        ],
        default: [true, true, true, true, true, true, true], // Sun, Mon, Tue, Wed, Thur, Fri, Sat
      },
    },
  };

  return (
    <Wrapper>
      <JStorageForm
        schema={schema}
        uiSchema={uiSchema}
        instance={calendar}
        onSubmit={onSubmit}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default CalendarForm;
